@import "https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap";
@font-face {
  font-family: robotech;
  src: url("robotech.e9f8e6e0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Aptos Black;
  src: url("aptos-black.dc3f3fb4.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.target {
  font-kerning: none;
}

html.lenis, html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

* {
  box-sizing: border-box;
}

html, body, main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, a, li {
  color: #3c4b5d;
  font-optical-sizing: auto;
  margin: 0;
  padding: 0;
  font-family: Manrope, sans-serif;
  font-style: normal;
  line-height: normal;
}

.title-1 {
  font-size: clamp(3.25rem, 3.0893rem + .8036vw, 3.8125rem);
}

.title-2 {
  font-size: clamp(2.25rem, 2.0714rem + .8929vw, 2.875rem);
}

.title-3 {
  font-size: clamp(2rem, 1.9643rem + .1786vw, 2.125rem);
}

.title-4 {
  font-size: clamp(1.5625rem, 1.5089rem + .2679vw, 1.75rem);
}

.title-5 {
  font-size: clamp(.875rem, .7857rem + .4464vw, 1.1875rem);
}

.par {
  font-size: clamp(.75rem, .6786rem + .3571vw, 1rem);
}

.red {
  color: red;
  font-weight: 700 !important;
}

.yellow {
  color: #f6cf2b;
}

.blue {
  color: #0c7d91;
}

.light-blue {
  color: #01c5d5;
}

.green {
  color: #54ad46;
}

.orange {
  color: #ee8d1c;
}

.yellow-bg {
  background-color: #f6cf2b;
}

.blue-bg {
  background-color: #01c5d5;
}

.green-bg {
  background-color: #54ad46;
}

.orange-bg {
  background-color: #ee8d1c;
}

.ultra {
  color: #f9fafb;
  background-color: #f6cf2b;
  border-top-left-radius: 6px;
  font-weight: 700;
}

.light {
  color: #f9fafb;
  background-color: #01c5d5;
  font-weight: 700;
}

.estandar {
  color: #f9fafb;
  background-color: #54ad46;
  font-weight: 700;
}

.premium {
  color: #f9fafb;
  background-color: #ee8d1c;
  border-top-right-radius: 6px;
  padding: 6px;
  font-weight: 700;
}

.separador {
  color: #18344c;
  text-transform: uppercase;
  font-weight: 800 !important;
}

.table-img img {
  width: 10vw;
}

.reset {
  z-index: -1;
  opacity: .2;
  background-color: #fff;
  background-image: url("gas-2.b5196622.png");
  background-size: cover;
  width: 100%;
  min-height: 200vh;
  margin: 0;
  padding: 0;
  position: absolute;
}

a {
  cursor: pointer;
}

.btn {
  font-optical-sizing: auto;
  color: #f9fafb;
  cursor: pointer;
  z-index: 5;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
  font-family: Manrope, sans-serif;
  font-size: clamp(.75rem, .6786rem + .3571vw, 1rem);
  font-style: normal;
  line-height: normal;
  position: relative;
}

.shadow {
  box-shadow: 0 10px 20px #00000026, 0 3px 6px #0000001a;
}

.front {
  z-index: 5;
  position: relative;
}

.mask {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.wa-button {
  z-index: 9999;
  width: fit-content;
  position: fixed;
  bottom: 2vh;
  right: 2vw;
}

.wa-button img {
  filter: drop-shadow(0 5px 15px #0003);
  width: 5em;
}

a.btn-con {
  text-decoration: none;
}

#particles-js {
  z-index: 1;
  opacity: .9;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

header {
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid #00c5d5;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 2vh 8vw;
  display: flex;
  top: 0;
  left: 0;
  position: fixed !important;
}

header img {
  width: 148px;
}

header .menu {
  gap: 1em;
  display: flex;
}

header .menu a {
  color: #18344c;
  cursor: pointer;
  padding: 8px 0;
  font-weight: 800;
  text-decoration: none;
}

header .menu a:last-child {
  z-index: 5;
  cursor: pointer;
  background: linear-gradient(100deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
  border-radius: 50px;
  padding: 8px 12px;
}

header .menu a:last-child p {
  color: #fff;
}

.hero {
  z-index: 15;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 90vh;
  padding: 12vh 8vw;
  display: flex;
  position: relative;
}

.hero .hero-sub {
  margin-bottom: .2em;
}

.hero .hero-title {
  text-wrap: balance;
  width: 60%;
  font-weight: 800;
  line-height: 1;
}

.hero .hero-title .x {
  color: red;
  font-family: robotech, sans-serif;
}

.hero .hero-des {
  width: 50%;
  margin-top: 1em;
  font-weight: 500;
  line-height: 107%;
}

.hero .btn-con {
  z-index: 5;
  cursor: pointer;
  background: linear-gradient(100deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
  border-radius: 50px;
  align-items: center;
  gap: .8em;
  padding: 8px 1.5em;
  display: flex;
  position: absolute;
  bottom: 10vh;
  left: 9.5vw;
}

.hero .btn-con:hover {
  background: linear-gradient(154deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
  transition: all .2s;
  box-shadow: 0 10px 20px #0000, 0 3px 6px #0000;
}

.hero .btn-con .arrow {
  height: 20px;
}

.hero .diagram {
  width: 40%;
  position: absolute;
  top: 50%;
  right: 3vw;
  transform: translateY(-50%);
}

.hero .pet {
  width: 100px;
  position: absolute;
  bottom: 3vh;
  left: 1vw;
}

.swiper {
  background-color: #fff;
  width: 100%;
  height: 80vh;
}

.swiper .swiper-wrapper {
  margin: 0;
  padding: 0;
}

.swiper .swiper-wrapper .swiper-slide {
  text-align: center;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 16vh 8vw;
  font-size: 18px;
  display: flex;
}

.swiper .swiper-wrapper .swiper-slide .ribbon-txt {
  text-align: center;
  color: #f9fafb;
  text-wrap: balance;
  font-weight: 800;
}

.swiper .swiper-wrapper .swiper-slide .ribbon-txt .x {
  color: red;
  font-family: robotech, sans-serif;
}

.swiper .swiper-wrapper .swiper-slide .btn-con {
  z-index: 5;
  cursor: pointer;
  background: linear-gradient(100deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
  border-radius: 50px;
  align-items: center;
  gap: .8em;
  width: fit-content;
  padding: 8px 2em;
  transition: all .2s;
  display: flex;
}

.swiper .swiper-wrapper .swiper-slide .btn-con button {
  font-weight: 700;
}

.swiper .swiper-wrapper .slide-1 {
  text-transform: uppercase;
  background-blend-mode: screen;
  background-color: #0000;
  background-image: none, url("gas-1.18bae47c.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.swiper .swiper-wrapper .slide-2 {
  text-transform: uppercase;
  background-blend-mode: screen;
  background-color: #0000;
  background-image: none, url("gas-2.b5196622.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.swiper .swiper-wrapper .slide-3 {
  text-transform: uppercase;
  background-blend-mode: screen;
  background-color: #0000;
  background-image: none, url("gas-3.674fb0c4.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.ventajas {
  background: linear-gradient(#0000 15%, #fff 95%);
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4em;
  min-height: 100vh;
  padding: 12vh 8vw;
  display: flex;
}

.ventajas .pc {
  border-radius: 500px;
  width: 34vw;
  position: absolute;
  left: 4vw;
}

.ventajas .mask {
  width: 60%;
}

.ventajas .mask .ventaja {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.ventajas .mask .ventaja .title-con {
  gap: 1em;
  display: flex;
}

.ventajas .mask .ventaja .title-con img {
  width: 4vw;
}

.ventajas .mask .ventaja .title-con .ventaja-titulo {
  color: #06a1b3;
  text-wrap: balance;
  line-height: 107%;
}

.ventajas .mask .ventaja .title-con .ventaja-titulo span {
  color: red;
  font-family: robotech, sans-serif;
  line-height: 0;
}

.planes {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  min-height: 100vh;
  padding: 12vh 8vw;
  display: flex;
  overflow: hidden;
}

.planes .plan-titulo .x {
  color: red;
  font-family: robotech, sans-serif;
}

.planes .bento {
  justify-content: center;
  gap: 1em;
  width: 100%;
  display: flex;
}

.planes .bento .bento-img {
  width: 33%;
  height: auto;
}

.planes .bento .bento-img img {
  object-fit: cover;
  border-radius: 1em;
  width: 100%;
  height: 100%;
}

.planes .bento .plan-column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 33%;
  display: flex;
}

.planes .bento .plan-column .plan {
  background-color: #fff;
  background-position: center;
  border: 1px solid #caced4;
  border-radius: 1em;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: 100%;
  min-height: 50vh;
  padding: 2em;
  display: flex;
}

.planes .bento .plan-column .plan h5 {
  font-family: Aptos Black, sans-serif;
  font-weight: 700;
}

.planes .bento .plan-column .plan .img-cont {
  margin: 0;
  padding: 0;
}

.planes .bento .plan-column .plan .img-cont .hide {
  padding: 0;
}

.planes .bento .plan-column .plan .img-cont .hide img {
  width: 60%;
  margin: 0 auto;
  display: block;
}

.planes .bento .plan-column .plan p {
  text-align: center;
  font-weight: 600;
}

.planes .bento .plan-column .plan .btn-con {
  z-index: 5;
  cursor: pointer;
  border-radius: 50px;
  align-items: center;
  gap: .8em;
  width: fit-content;
  padding: 8px 2em;
  transition: all .2s;
  display: flex;
}

.planes .bento .plan-column .plan .btn-con button {
  font-weight: 600;
}

.planes .bento .plan-column .plan .btn-con .arrow {
  height: 20px;
}

.planes .bento .plan-column .plan .btn-1 {
  background: #f6cf2b;
}

.planes .bento .plan-column .plan .btn-2 {
  background: #54ad46;
}

.planes .bento .plan-column .plan .btn-3 {
  background: #01c5d5;
}

.planes .bento .plan-column .plan .btn-4 {
  background: #ee8d1c;
}

.planes .pet {
  width: 180px;
  position: absolute;
  top: 42%;
  left: 10vw;
}

.tabla {
  background: linear-gradient(#fff 5%, #0000 80%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  min-height: 100vh;
  padding: 12vh 8vw;
  display: flex;
}

.tabla .plan-titulo {
  text-align: center;
  width: 100%;
}

.tabla table {
  backdrop-filter: blur(8px);
  border: 1px solid #caced4;
  border-radius: 1em;
  width: 100%;
  padding: 1.5em 1em;
  font-family: Manrope, sans-serif;
}

.tabla table .btn-con {
  z-index: 5;
  cursor: pointer;
  border-radius: 50px;
  align-items: center;
  gap: .8em;
  padding: 8px 12px;
  color: #fff !important;
}

.tabla table tr td:first-child {
  text-align: left;
  border-top: 0 solid #0000;
  border-bottom: 0 solid #0000;
  padding: 4px 1em 4px 0;
  font-weight: 400;
}

.tabla table tr:last-child td {
  border: none;
}

.tabla table td {
  text-align: center;
  height: vh;
  border-left: none;
  border-right: none;
  outline: none;
  gap: .5em;
  padding: 4px .8em;
  font-weight: 800;
}

.tabla table td img {
  width: 1.5vw;
}

.tabla table .main-ico {
  align-items: center;
  display: flex;
}

.tabla .cond {
  text-align: left;
  flex-direction: column;
  gap: .5em;
  width: 100%;
  display: flex;
}

.tabla .cond .list {
  align-items: center;
  gap: 1em;
  display: flex;
}

.tabla .cond .list img {
  width: 24px;
}

.tabla .cond .list p {
  font-weight: 600;
}

.tabla .tarjetas {
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  padding: 8vh 0 0;
  display: flex;
}

.tabla .tarjetas .txt {
  font-weight: 800;
}

.tabla .tarjetas img {
  width: 48px;
}

.contact {
  background: url("gas-2.b5196622.png") bottom;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  padding: 8vh 8vw;
  display: flex;
}

.contact .adress {
  color: #f9fafb;
  text-align: center;
  font-weight: 800;
  text-decoration: none;
}

.contact .social-con {
  gap: 1em;
  display: flex;
}

.contact .legal {
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  display: flex;
}

.contact .legal .legal-txt {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.derechos {
  background-color: #18344c;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4vh 8vw;
  display: flex;
}

.derechos .derechos-txt {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

@media (width <= 768px) {
  .wa-button {
    z-index: 9999;
    width: fit-content;
    position: fixed;
    bottom: 2vh;
    right: 2vw;
  }

  .wa-button img {
    filter: drop-shadow(0 5px 15px #0003);
    width: 3.5em;
  }

  main {
    overflow: hidden;
  }

  header {
    z-index: 999;
    background-color: #fff;
    border-bottom: 1px solid #00c5d5;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 100%;
    height: fit-content;
    padding: 2vh 8vw;
    display: flex;
    top: 0;
    left: 0;
    position: fixed !important;
  }

  header img {
    width: 88px;
  }

  header .menu {
    gap: 1em;
    display: flex;
  }

  header .menu a {
    color: #18344c;
    cursor: pointer;
    padding: 8px 0;
    font-weight: 800;
    text-decoration: none;
  }

  header .menu a:last-child {
    z-index: 5;
    cursor: pointer;
    background: linear-gradient(100deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
    border-radius: 50px;
    padding: 8px 12px;
  }

  header .menu a:last-child p {
    color: #fff;
  }

  .hero {
    z-index: 15;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
    padding: 8vh 8vw;
    display: flex;
    position: relative;
  }

  .hero .hero-sub {
    margin-bottom: .2em;
    font-size: 1em;
  }

  .hero .hero-title {
    text-wrap: balance;
    width: 100%;
    font-size: 2.8em;
    font-weight: 800;
    line-height: 98%;
  }

  .hero .hero-title .x {
    color: red;
    font-family: robotech, sans-serif;
  }

  .hero .hero-des {
    width: 100%;
    margin-top: 1em;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 107%;
  }

  .hero .btn-con {
    z-index: 5;
    cursor: pointer;
    background: linear-gradient(100deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
    border-radius: 50px;
    align-items: center;
    gap: .8em;
    margin-top: 1.5em;
    padding: 8px 1.5em;
    display: flex;
    position: static;
  }

  .hero .btn-con:hover {
    background: linear-gradient(154deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
    transition: all .2s;
    box-shadow: 0 10px 20px #0000, 0 3px 6px #0000;
  }

  .hero .btn-con .arrow {
    height: 20px;
  }

  .hero .diagram {
    width: 80%;
    position: absolute;
    top: auto;
    bottom: 2em;
    right: 50%;
    transform: translateX(50%);
  }

  .hero .pet {
    width: 100px;
    display: none;
    position: absolute;
    bottom: 3vh;
    left: 1vw;
  }

  .swiper {
    background-color: #fff;
    width: 100%;
    min-height: 80vh;
  }

  .swiper .swiper-wrapper {
    margin: 0;
    padding: 0;
  }

  .swiper .swiper-wrapper .swiper-slide {
    text-align: center;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 8vh 8vw;
    font-size: 18px;
    display: flex;
  }

  .swiper .swiper-wrapper .swiper-slide img {
    width: 60%;
  }

  .swiper .swiper-wrapper .swiper-slide .ribbon-txt {
    text-align: center;
    color: #f9fafb;
    text-wrap: balance;
    font-size: 1.5em;
    font-weight: 800;
    line-height: 115%;
  }

  .swiper .swiper-wrapper .swiper-slide .ribbon-txt .x {
    color: red;
    font-family: robotech, sans-serif;
    font-size: 1.8em;
    line-height: 1;
  }

  .swiper .swiper-wrapper .swiper-slide .btn-con {
    z-index: 5;
    cursor: pointer;
    background: linear-gradient(100deg, #03b3c4 35.19%, #00c5d5 50%, #03b3c4 64.81%);
    border-radius: 50px;
    align-items: center;
    gap: .8em;
    width: fit-content;
    padding: 8px 2em;
    transition: all .2s;
    display: flex;
  }

  .swiper .swiper-wrapper .swiper-slide .btn-con button {
    font-weight: 700;
  }

  .swiper .swiper-wrapper .slide-1 {
    text-transform: uppercase;
    background-blend-mode: screen;
    background-color: #0000;
    background-image: none, url("gas-1.18bae47c.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .swiper .swiper-wrapper .slide-2 {
    text-transform: uppercase;
    background-blend-mode: screen;
    background-color: #0000;
    background-image: none, url("gas-2.b5196622.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .swiper .swiper-wrapper .slide-3 {
    text-transform: uppercase;
    background-blend-mode: screen;
    background-color: #0000;
    background-image: none, url("gas-3.674fb0c4.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .ventajas {
    background: linear-gradient(#0000 15%, #fff 95%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4em;
    min-height: 100vh;
    padding: 8vh 8vw;
    display: flex;
  }

  .ventajas .pc {
    border-radius: 500px;
    width: 100%;
    margin-bottom: -2em;
    position: static;
    left: 0;
  }

  .ventajas .mask {
    width: 100%;
  }

  .ventajas .mask .ventaja {
    flex-direction: column;
    gap: 1em;
    width: 100%;
    display: flex;
  }

  .ventajas .mask .ventaja .title-con {
    flex-direction: column;
    gap: 1em;
    display: flex;
  }

  .ventajas .mask .ventaja .title-con img {
    width: 32px;
  }

  .ventajas .mask .ventaja .title-con .ventaja-titulo {
    color: #06a1b3;
    text-wrap: balance;
    font-size: 1.5em;
    line-height: 107%;
  }

  .ventajas .mask .ventaja .title-con .ventaja-titulo span {
    color: red;
    font-family: robotech, sans-serif;
    line-height: 0;
  }

  .ventajas .mask .ventaja .ventaja-des {
    font-size: .8em;
  }

  .planes {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    min-height: 100vh;
    padding: 8vh 8vw;
    display: flex;
    overflow: hidden;
  }

  .planes .bg-img {
    height: 100%;
  }

  .planes .plan-titulo {
    font-size: 2em;
    line-height: 1;
  }

  .planes .plan-titulo .x {
    color: red;
    font-family: robotech, sans-serif;
  }

  .planes .bento {
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    width: 100%;
    display: flex;
  }

  .planes .bento .bento-img {
    width: 33%;
    height: auto;
  }

  .planes .bento .bento-img img {
    object-fit: cover;
    border-radius: 1em;
    width: 100%;
    height: 100%;
  }

  .planes .bento .plan-column {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
    display: flex;
  }

  .planes .bento .plan-column .plan {
    background-color: #fff;
    background-position: center;
    border: 1px solid #caced4;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 100%;
    min-height: 50vh;
    padding: 2em;
    display: flex;
  }

  .planes .bento .plan-column .plan h5 {
    font-family: Aptos Black, sans-serif;
    font-weight: 700;
  }

  .planes .bento .plan-column .plan .img-cont {
    margin: 0;
    padding: 0;
  }

  .planes .bento .plan-column .plan .img-cont .hide {
    padding: 0;
  }

  .planes .bento .plan-column .plan .img-cont .hide img {
    width: 60%;
    margin: 0 auto;
    display: block;
  }

  .planes .bento .plan-column .plan p {
    text-align: center;
    font-weight: 600;
  }

  .planes .bento .plan-column .plan .btn-con {
    z-index: 5;
    cursor: pointer;
    border-radius: 50px;
    align-items: center;
    gap: .8em;
    width: fit-content;
    padding: 8px 2em;
    transition: all .2s;
    display: flex;
  }

  .planes .bento .plan-column .plan .btn-con button {
    font-weight: 600;
  }

  .planes .bento .plan-column .plan .btn-con .arrow {
    height: 20px;
  }

  .planes .bento .plan-column .plan .btn-1 {
    background: #f6cf2b;
  }

  .planes .bento .plan-column .plan .btn-2 {
    background: #54ad46;
  }

  .planes .bento .plan-column .plan .btn-3 {
    background: #01c5d5;
  }

  .planes .bento .plan-column .plan .btn-4 {
    background: #ee8d1c;
  }

  .planes .pet {
    z-index: 99;
    width: 6em;
    position: absolute;
    top: auto;
    bottom: 1em;
    left: -3em;
  }

  .tabla {
    background: linear-gradient(#fff 5%, #0000 80%);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5em;
    min-height: 100vh;
    padding: 12vh 8vw;
    display: flex;
    overflow: auto;
  }

  .tabla .plan-titulo {
    text-align: center;
    width: 100%;
    font-size: 2em;
  }

  .tabla table {
    backdrop-filter: blur(8px);
    border: 1px solid #caced4;
    border-radius: 1em;
    width: 100%;
    padding: 1.5em 1em;
    font-family: Manrope, sans-serif;
    overflow: auto;
  }

  .tabla table .table-img img {
    width: 100%;
  }

  .tabla table .btn-con {
    z-index: 5;
    cursor: pointer;
    border-radius: 50px;
    align-items: center;
    gap: .8em;
    padding: 8px 12px;
    color: #fff !important;
  }

  .tabla table tr td:first-child {
    text-align: left;
    border-top: 0 solid #0000;
    border-bottom: 0 solid #0000;
    width: 210px;
    padding: 4px 1em 4px 0;
    font-weight: 400;
  }

  .tabla table tr:last-child td {
    border: none;
  }

  .tabla table td {
    text-align: center;
    border-left: none;
    border-right: none;
    outline: none;
    gap: .5em;
    height: auto;
    padding: 4px .8em;
    font-size: .8em;
    font-weight: 800;
  }

  .tabla table td img {
    width: 1.5em;
  }

  .tabla table .main-ico {
    align-items: center;
    display: flex;
  }

  .tabla .cond {
    text-align: left;
    flex-direction: column;
    gap: .5em;
    width: 100%;
    display: flex;
  }

  .tabla .cond .list {
    align-items: center;
    gap: 1em;
    display: flex;
  }

  .tabla .cond .list img {
    width: 24px;
  }

  .tabla .cond .list p {
    font-weight: 600;
  }

  .tabla .tarjetas {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    width: 100%;
    padding: 8vh 0 0;
    display: flex;
  }

  .tabla .tarjetas .txt {
    font-weight: 800;
  }

  .tabla .tarjetas img {
    width: 48px;
  }

  .contact {
    background: url("gas-2.b5196622.png") bottom;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    padding: 8vh 8vw;
    display: flex;
  }

  .contact .adress {
    color: #f9fafb;
    text-align: center;
    font-weight: 800;
    text-decoration: none;
  }

  .contact .social-con {
    gap: 1em;
    display: flex;
  }

  .contact .legal {
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    display: flex;
  }

  .contact .legal .legal-txt {
    color: #fff;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
  }

  .derechos {
    background-color: #18344c;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4vh 8vw;
    display: flex;
  }

  .derechos .derechos-txt {
    color: #fff;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
  }
}
/*# sourceMappingURL=index.12f2f5ff.css.map */
